.multi-step-form {
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.header-form {
  background-color: blue;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header-form h2 {
  color: white;
  margin: 0;
  font-size: 18px;
}

.form-content {
  padding: 20px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

button[disabled] {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

/* Añadir estilos específicos para móviles */
@media (max-width: 767px) {
  .multi-step-form {
    max-width: 100%;
    padding: 10px;
  }

  .header-form h2 {
    font-size: 16px;
  }

  button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
