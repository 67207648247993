/* App.css */

/* Estilos generales para dispositivos móviles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
}

.container {
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden; /* Evita el desplazamiento */
}

.mobile-app {
  width: 100%;
  max-width: 500px; /* Opcional: limita el ancho máximo */
  box-sizing: border-box;
}

/* Ocultar la aplicación en pantallas más grandes */
@media (min-width: 768px) {
  .mobile-app {
    display: none;
  }

  .desktop-message {
    display: block;
    font-size: 24px;
    color: red;
    margin-top: 20%;
  }
}

/* Mostrar la aplicación en pantallas más pequeñas */
@media (max-width: 767px) {
  .mobile-app {
    display: block;
    margin: 0 auto; /* Centra el contenido horizontalmente */
  }

  .desktop-message {
    display: none;
  }

  form {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin-top: 0;
  }
  
  button {
    margin: 5px;
  }
}


/* Estilos específicos para la orientación vertical (portrait) */
/* .container.portrait {
  background-color: lightblue;
} */

/* Estilos específicos para la orientación horizontal (landscape) */
/* .container.landscape {
  background-color: lightgreen; 
} */

