.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70%;
}

.sigCanvas {
  border: 1px solid #000;
  border-radius: 5px;
  width: 90%;
  height: 55vh;
}

.signature-buttons {
  margin-top: 10px;
}

button {
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}
