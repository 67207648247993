.step-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin: 0 10px;
    position: relative;
    top: 18px;
    margin-bottom: 15px;
  }
  
  .step.active {
    background-color: #4caf50;
    color: white;
  }
  
  .step-number {
    font-size: 16px;
    font-weight: bold;
    margin: 5px;
  }
  
  .step:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -20px;
    width: 30px;
    height: 2px;
    background-color: #e0e0e0;
  }
  
  .step.active:not(:last-child)::after {
    background-color: #4caf50;
  }
  